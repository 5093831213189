.video-library-user-filter {
  width: 262px !important;
  margin-left: 8px;
}

.video-library-user-filter .Select-arrow-zone {
  height: 30px;
}

.video-library-user-filter .Select-placeholder {
  line-height: 30px !important;
}

.video-library-user-filter .Select-input {
  height: 30px !important;
}

.video-library-user-filter .Select-input input {
  line-height: 30px !important;
  padding: 0 !important;
}
