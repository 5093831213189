#video-library-react-container {
  background-color: var(--color-p1-3);
  display: flex;
  margin-left: 48px;
}

#video-library-react-container .tide-base-modal.tide-base-modal--open,
#unified-root .tide-base-modal.tide-base-modal--open {
  z-index: 5;
}

#video-library-react-container .modal-asset,
#unified-root .modal-asset {
  display: block;
  margin: 0 auto;
  width: 80%;
}

#video-library-react-container .modal-subheader,
#unified-root .modal-subheader {
  margin-top: 16px;
}

.video-library__toast-container {
  position: fixed;
  left: 279px;
  bottom: 20px;
  z-index: 3;
}

.tc-subnav-wrapper--collapsed + .video-library__toast-container {
  left: 87px;
  transition: 0.375s;
}

.drift-left-nav {
  position: fixed;
}

.tc-subnav-wrapper {
  position: sticky;
  height: 100vh;
  top: 0;
  z-index: 2;
}

.video-library-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: scroll;
}

.video-library-header {
  position: sticky;
  min-width: 900px;
  top: 0;
  z-index: 1;
}

.tide-base-modal {
  z-index: 2;
}

.title-and-upload {
  display: flex;
}

.video-library-header__title {
  /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
  font-family: ProximaNova-Semibold;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: -0.16px;
  color: var(--color-p1-10);
  margin: 24px 32px;
}

.video-library-header .upload-video {
  margin-right: 16px;
}

.video-library-header .new-folder {
  margin-right: 16px;
}

.video-library-body {
  margin: 24px 32px !important;
  flex-grow: 1;
  min-width: 868px;
}

.video-library-body.empty {
  min-height: 300px;
  flex-grow: 0;
  border: none;
}

.video-library-filter-controls {
  display: flex;
}

.video-library-search,
.video-library-search .tide-input {
  height: 36px;
}

.video-library-filter-controls .DateRangePicker {
  margin-left: 8px;
}

.video-library-filter-controls .DateRangePickerInput {
  height: 26px;
}

#unified-root .video-library-filter-controls .DateRangePickerInput {
  height: 26px;
}

.video-library-filter-controls .DateInput_input__small {
  line-height: 20px;
}

.video-library-filter-controls .DateRangePickerInput_arrow {
  margin: 4px 6px 0;
}

#unified-root .video-library-filter-controls .DateRangePickerInput_arrow {
  margin: 8px 6px 0;
}

.video-library-filter-controls .DateRangePickerInput_arrow svg path {
  fill: var(--color-p3-4);
}

.video-library-filter-controls .DateInput_input::placeholder {
  text-transform: lowercase;
}

.video-library-filter-controls .DateInput_input:not(.DateInput_input__focused)::placeholder {
  color: var(--color-p1-5);
}

.video-library-filter-controls .source-type-select {
  max-width: 200px;
  margin-left: 8px;
}

.video-library__video-count {
  display: flex;
  align-items: center;
  margin-left: 8px;
}

.video-library__export-csv-button {
  margin-left: auto;
  height: 32px;
}

.video-library-table {
  margin-top: 16px;
}

.video-library-row-edit-title {
  padding-left: 16px;
}

.row-title-flex {
  flex: auto;
  min-width: 236px;
}
