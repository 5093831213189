.video-library__empty-state {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  border-top: none;
}

.video-library__empty-state-header {
  margin-top: 28px;
  text-align: center;
}

.tide-heading + .video-library__empty-state-copy {
  margin-top: 12px;
  text-align: center;
  max-width: 450px;
}

.video-library__empty-state-button {
  margin-top: 12px;
}
